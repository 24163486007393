import { FC, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  DeleteOutlined,
  EditOutlined,
  FolderOutlined,
} from '@ant-design/icons';
import styled from '@emotion/styled';
import { Button, Space, Table, TablePaginationConfig, Typography } from 'antd';

import { useGetProjectsQuery } from '../../api/projects';
import { useDeleteProject } from '../../hooks/projects/use-delete-project';
import { usePagination } from '../../hooks/use-pagination';
import {
  TProjectColumnsData,
  getProjectListTableColumns,
} from '../../tables/project';
import { CreateProjectModal } from '../modals/create-project-modal';

import { ColumnsType } from 'antd/lib/table';

const Title = styled(Typography.Title)`
  margin-top: 0;
  margin-bottom: 0 !important; // AntDesign не позволяет обойтись без !important
`;

const Header = styled(Space)`
  margin-bottom: 30px;
`;

export const HomePage: FC = () => {
  const [createProjectModalOpen, setCreateProjectModalOpen] = useState(false);
  const { pagination, onPageChange, currentPage } = usePagination(20);

  const { data, isLoading } = useGetProjectsQuery(pagination);
  const navigate = useNavigate();

  const [editProjectId, setEditProjectId] = useState<string | undefined>();

  const handleDeleteProject = useDeleteProject();

  const handleStartEdit = (id: string) => {
    setEditProjectId(id);
    setCreateProjectModalOpen(true);
  };

  const handleAddProject = () => {
    setEditProjectId(undefined);
    setCreateProjectModalOpen(true);
  };

  const handleOpenProject = (id: string) => {
    navigate(id);
  };

  const columnData: TProjectColumnsData[] = useMemo(
    () =>
      data
        ? data.projects.map((el) => ({
            key: el.id,
            name: el.name,
            feedUrl: el.feedURL,
            scheduleEnabled: el.scheduleEnabled,
            createAt: el.createAt,
            updateAt: el.updateAt,
          }))
        : [],
    [data]
  );

  const columns: ColumnsType<TProjectColumnsData> = useMemo(
    () =>
      getProjectListTableColumns([
        {
          content: 'Открыть',
          key: 'open',
          icon: <FolderOutlined />,
          action: handleOpenProject,
        },
        {
          content: 'Редактировать',
          key: 'edit',
          icon: <EditOutlined />,
          action: handleStartEdit,
        },
        {
          content: 'Удалить',
          key: 'delete',
          icon: <DeleteOutlined />,
          action: handleDeleteProject,
        },
      ]),
    [handleDeleteProject]
  );

  return (
    <div>
      <Header size={12}>
        <Title level={2}>Проекты</Title>
        <Button type="primary" onClick={handleAddProject}>
          Добавить проект
        </Button>
      </Header>

      <Table
        dataSource={columnData}
        columns={columns}
        loading={isLoading}
        pagination={
          (data?.count ?? 0) > pagination.take
            ? ({
                pageSize: pagination.take,
                onChange: onPageChange,
                total: data?.count,
                current: currentPage,
              } as TablePaginationConfig)
            : false
        }
      />
      <CreateProjectModal
        open={createProjectModalOpen}
        setOpen={setCreateProjectModalOpen}
        existProjectId={editProjectId}
      />
    </div>
  );
};
