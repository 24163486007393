import { useCallback, useEffect, useMemo, useState } from 'react';

import { TPaginationPayload } from '../types/api';

export type TUsePagination = {
  pagination: Required<TPaginationPayload>;
  onPageChange: (page: number, pageSize: number) => void;
  currentPage: number;
};

const DEFAULT_TAKE = 10;

export const defaultPaginationState: TUsePagination = {
  pagination: {
    take: DEFAULT_TAKE,
    skip: 1,
  },
  currentPage: 1,
  onPageChange: () => {},
};

export function usePagination(
  defaultTake: number = DEFAULT_TAKE
): TUsePagination {
  const [currentPage, setCurrentPage] = useState(1);
  const [take, setTake] = useState(defaultTake);
  const [skip, setSkip] = useState(0);

  useEffect(() => {
    setSkip(take * (currentPage - 1));
  }, [currentPage, take]);

  const onPageChange = useCallback(
    (page: number, pageSize: number) => {
      if (take !== pageSize) {
        setCurrentPage(1);
      } else {
        setCurrentPage(page);
      }

      setTake(pageSize);
    },
    [take]
  );

  return useMemo(
    () => ({
      pagination: {
        take,
        skip,
      },
      onPageChange,
      currentPage,
    }),
    [take, skip, onPageChange, currentPage]
  );
}
