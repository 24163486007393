import { FC, PropsWithChildren } from 'react';

import styled from '@emotion/styled';

const Wrapper = styled.div`
  height: calc(100vh - 84px);
  min-height: 400px;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
`;

export const FinancePlansGrid: FC<PropsWithChildren> = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};
