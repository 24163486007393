import { FC, useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

import { LoadingOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Empty } from 'antd';
import dayjs from 'dayjs';

import {
  useGetProjectGenerationTasksQuery,
  useGetTaskLogsQuery,
} from '../../api/generation-tasks';
import { BackToMainPane } from '../../components/ui/back-to-main-pane';
import { ESubPaths, TSubPathsPathParams } from '../../routes/route-paths';

type TProps = {
  projectId?: string;
};

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  font-size: 34px;
`;

export const ProjectGenerationLogsTab: FC<TProps> = ({ projectId }) => {
  const navigate = useNavigate();

  const { data: projectTaskData } = useGetProjectGenerationTasksQuery(
    projectId as string,
    {
      skip: !projectId,
    }
  );

  const { generationId } = useParams<TSubPathsPathParams<ESubPaths.BANNERS>>();

  const lastTask = useMemo(
    () =>
      projectTaskData?.generationTasks?.[0]
        ? projectTaskData?.generationTasks[0]
        : undefined,
    [projectTaskData]
  );

  const logsId: string | undefined = useMemo(
    () => generationId ?? lastTask?.id,
    [generationId, lastTask]
  );

  const { data, isLoading } = useGetTaskLogsQuery(logsId as string, {
    skip: !logsId,
    refetchOnMountOrArgChange: true,
  });

  const logsText: string = useMemo(
    () =>
      data
        ? data
            .map((el) => {
              const log = `${dayjs(el.updatedAt).format('DD MMMM YYYY HH:mm:ss:ms')} - ${el.message}`;

              let additionalParamsLogs = '';

              Object.keys(el.data).forEach((key) => {
                if (key !== 'message') {
                  additionalParamsLogs += `${key} - ${JSON.stringify(el.data[key])}\n`;
                }
              });

              return `${log}\n${additionalParamsLogs}\n`;
            })
            .join('\n')
        : 'No any logs',
    [data]
  );

  const goBack = useCallback(() => {
    navigate(
      generationId
        ? `../../${ESubPaths.GENERATION_TASKS}`
        : `../${ESubPaths.GENERATION_TASKS}`,
      { relative: 'path' }
    );
  }, [generationId]);

  return (
    <div>
      <BackToMainPane
        title={generationId ? 'Логи генерации' : 'Логи последней генерации'}
        backTooltip="Показать все генерации"
        onGoBack={goBack}
      />
      {!(generationId || lastTask?.id) && (
        <Empty
          image={Empty.PRESENTED_IMAGE_DEFAULT}
          description="Нет доступных генераций для просмотра логов"
        />
      )}
      {isLoading && (
        <LoadingWrapper>
          <LoadingOutlined />
        </LoadingWrapper>
      )}
      <SyntaxHighlighter language="text" style={a11yDark} showLineNumbers>
        {logsText}
      </SyntaxHighlighter>
    </div>
  );
};
