import { InternalFieldProps } from 'rc-field-form/lib/Field';

export const normFileList: InternalFieldProps['getValueFromEvent'] = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

export const normSingleFile: InternalFieldProps['getValueFromEvent'] = (e) => {
  if (Array.isArray(e)) {
    return e[0];
  }
  return e?.file;
};
