import React, { FC } from 'react';

import { Checkbox, Form, InputNumber, Space } from 'antd';

export const GenerationSettingsForm: FC = () => (
  <>
    <Form.Item
      name="transactional"
      label="Генерация транзакцией"
      tooltip="При транзакционной генерации операция будет прервана при появлении любой ошибки"
      valuePropName="checked"
    >
      <Checkbox>Да</Checkbox>
    </Form.Item>
    <Form.Item
      name="skipUnavailable"
      label="Пропускать недоступные офферы"
      valuePropName="checked"
    >
      <Checkbox>Пропускать</Checkbox>
    </Form.Item>
    <Form.Item
      name="checkWidthOverflow"
      label="Проверять длину текстовых элементов"
      tooltip="При включенном параметре текст, подставляемый в шаблон, будет сравнен с текстом в шаблоне. Если новый текст не выходит за границы элемента, генерация будет выполнена."
      valuePropName="checked"
    >
      <Checkbox>Проверять</Checkbox>
    </Form.Item>
    <Space>
      <Form.Item name="from" label="SKU от">
        <InputNumber min={0} />
      </Form.Item>
      <Form.Item
        name="to"
        label="SKU до"
        dependencies={['from']}
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              const fromValue = getFieldValue('from');

              if (!fromValue || !value || fromValue < value) {
                return Promise.resolve();
              }
              if (fromValue >= value) {
                return Promise.reject(
                  new Error(`Минимальное значение - ${fromValue}`)
                );
              }

              return Promise.resolve();
            },
          }),
        ]}
      >
        <InputNumber min={1} />
      </Form.Item>
    </Space>
  </>
);
