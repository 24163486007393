import { FC, useCallback, useMemo, useState } from 'react';

import styled from '@emotion/styled';
import { Button, Space, Table, Typography } from 'antd';

import {
  useCreateYandexFeedMutation,
  useGetFeedsListQuery,
} from '../../api/feeds';
import {
  TFileColumnsData,
  getFileTableColumns,
} from '../../tables/table-files';
import { TYandexFeed } from '../../types/yandex-feed';
import { CreateYandexFeedModal } from '../modals/create-yandex-feed-modal';

import { ColumnsType } from 'antd/lib/table';

const Title = styled(Typography.Title)`
  margin-top: 0;
  margin-bottom: 0 !important; // AntDesign не позволяет обойтись без !important
`;

const Header = styled(Space)`
  margin-bottom: 30px;
`;

export const FeedsPage: FC = () => {
  const { data, isLoading, refetch } = useGetFeedsListQuery();
  const [createFeedModalOpen, setCreateFeedModalOpen] = useState(false);

  const [createYandexFeed, { isLoading: isFeedCreationLoading }] =
    useCreateYandexFeedMutation();

  const handleOpenFeedModal = () => {
    setCreateFeedModalOpen(true);
  };

  const columnData: TFileColumnsData[] = useMemo(
    () =>
      data
        ? data.map((el) => ({
            key: el.id,
            originalName: el.originalName,
            location: el.location,
            size: el.size,
            updateAt: el.updateAt,
            createAt: el.createAt,
          }))
        : [],
    [data]
  );

  const columns: ColumnsType<TFileColumnsData> = useMemo(
    () => getFileTableColumns(),
    []
  );

  const handleCreateFeed: (feedData: TYandexFeed<number>) => Promise<string> =
    useCallback(async (feedData: TYandexFeed<number>) => {
      const response = await createYandexFeed(feedData).unwrap();
      refetch();

      return response;
    }, []);

  return (
    <div>
      <Header size={12}>
        <Title level={2}>Фиды</Title>
        <Button type="primary" onClick={handleOpenFeedModal}>
          Создать фид
        </Button>
      </Header>
      <Table
        dataSource={columnData}
        columns={columns}
        loading={isLoading}
        pagination={false}
      />
      <CreateYandexFeedModal
        open={createFeedModalOpen}
        setOpen={setCreateFeedModalOpen}
        isLoading={isFeedCreationLoading}
        handleCreateFeed={handleCreateFeed}
      />
    </div>
  );
};
