import { FC, useMemo } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Descriptions, Empty, Image, Space } from 'antd';
import dayjs from 'dayjs';

import { useGetProjectTemplateQuery } from '../../api/projects';
import { ELayerType } from '../../api/projects/types';
import { OfferTextDescription } from '../../components/ui/offer-description';

import { DescriptionsProps } from 'antd/es/descriptions';

type TProps = {
  projectId?: string;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 320px;
  min-height: 200px;
  font-size: 34px;
`;

const DescriptionsWrapper = styled.div`
  width: 100%;
`;

const ProjectTemplateTab: FC<TProps> = ({ projectId }) => {
  const { data, isLoading, error } = useGetProjectTemplateQuery(
    projectId as string,
    {
      skip: !projectId,
    }
  );

  const descriptionItems: DescriptionsProps['items'] = useMemo(
    () =>
      data
        ? ([
            {
              key: 'createAt',
              label: 'Дата создания',
              children: (
                <OfferTextDescription>
                  {dayjs(data.createdAt).format('DD MMMM YYYY')}
                </OfferTextDescription>
              ),
              span: 3,
            },
            {
              key: 'createAt',
              label: 'Дата обновления',
              children: (
                <OfferTextDescription>
                  {dayjs(data.createdAt).format('DD MMMM YYYY')}
                </OfferTextDescription>
              ),
              span: 3,
            },
            {
              key: 'variations',
              label: 'Вариации',
              children: (
                <Space>
                  {data.bannerTemplates.length > 0
                    ? data.bannerTemplates.map((el) => (
                        <span key={el.id}>
                          {el.frame.name} | {el.width}x{el.height}
                        </span>
                      ))
                    : 'Вариации не добавлены'}
                </Space>
              ),
              span: 3,
            },
            ...(data.bannerTemplates.length > 0
              ? data.bannerTemplates[0].frame.layers
                  .filter((el) => el.type !== ELayerType.FEED_PARAM)
                  .map((el, index) => ({
                    key: `${el.type}-${el.content}`,
                    label: `Слой №${index + 1}`,
                    children: (
                      <Image
                        src={el.content}
                        style={{
                          maxWidth: 320,
                          maxHeight: 320,
                          width: '100%',
                          height: '100%',
                        }}
                      />
                    ),
                    span: 3,
                  }))
              : []),
          ].filter(Boolean) as DescriptionsProps['items'])
        : [],
    [data]
  );

  const isTemplateEmpty = useMemo(
    () =>
      error &&
      'status' in error &&
      typeof error.status === 'number' &&
      error.status === 404,
    [error]
  );

  return (
    <Wrapper>
      {isLoading && (
        <LoadingWrapper>
          <LoadingOutlined />
        </LoadingWrapper>
      )}
      {isTemplateEmpty && (
        <LoadingWrapper>
          <Empty description="Шабон отсутствует" />
        </LoadingWrapper>
      )}
      {data && (
        <DescriptionsWrapper>
          <Descriptions
            bordered
            title="Шаблоны проекта"
            items={descriptionItems}
          />
        </DescriptionsWrapper>
      )}
    </Wrapper>
  );
};

export default ProjectTemplateTab;
