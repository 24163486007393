import { FC, MouseEventHandler, ReactNode } from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Button, Space, Tooltip, Typography } from 'antd';

const Title = styled(Typography.Title)`
  margin: 0 30px 0 0 !important;
`;

const GenerationBannersHead = styled(Space)`
  margin-block: 10px;
`;

type TProps = {
  title: string;
  backTooltip?: string;
  onGoBack: MouseEventHandler<HTMLButtonElement>;
  extraContent?: ReactNode;
};

export const BackToMainPane: FC<TProps> = ({
  title,
  backTooltip,
  onGoBack,
  extraContent,
}) => (
  <GenerationBannersHead align="center">
    {backTooltip ? (
      <Tooltip title={backTooltip} placement="left">
        <Button
          icon={<ArrowLeftOutlined />}
          shape="circle"
          onClick={onGoBack}
        />
      </Tooltip>
    ) : (
      <Button icon={<ArrowLeftOutlined />} shape="circle" onClick={onGoBack} />
    )}
    <Title level={3}>{title}</Title>
    {extraContent}
  </GenerationBannersHead>
);
