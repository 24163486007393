export enum EFinancePlanType {
  TESTING = 'TESTING',
  DESIGNER = 'DESIGNER',
  CORP = 'CORP',
}

export type TFinanceFeatures = {
  type: EFinancePlanType;
  skuLimit: number;
  updatesPerDay: number;
  price?: number;
  connectionPrice?: number;
};
