import { baseEmptyApi } from '../api';
import { TFont, TFontPayload } from '../generation-tasks/types';

export const fontsApi = baseEmptyApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getFonts: builder.query<TFont[], void>({
      query: () => ({
        url: '/api/fonts',
        method: 'GET',
      }),
    }),
    createFont: builder.mutation<TFont[], TFontPayload>({
      query: (font) => ({
        url: 'api/fonts',
        method: 'POST',
        body: font,
      }),
      async onQueryStarted(_, api) {
        const response = await api.queryFulfilled;
        api.dispatch(
          fontsApi.util.updateQueryData('getFonts', undefined, (data) => [
            ...data,
            ...response.data,
          ])
        );
      },
    }),
    deleteFont: builder.mutation<void, string>({
      query: (fontId) => ({
        url: `api/fonts/${fontId}`,
        method: 'DELETE',
      }),
      async onQueryStarted(arg, api) {
        const patchResult = api.dispatch(
          fontsApi.util.updateQueryData('getFonts', undefined, (data) =>
            data.filter((el) => el.id !== arg)
          )
        );

        try {
          await api.queryFulfilled;
        } catch (e) {
          patchResult.undo();
        }
      },
    }),
  }),
});

export const {
  useGetFontsQuery,
  useCreateFontMutation,
  useDeleteFontMutation,
} = fontsApi;
