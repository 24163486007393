import { DeleteOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import dayjs from 'dayjs';

import { TFileColumnsData } from './table-files';

import { ColumnsType } from 'antd/lib/table';

export type TFontsColumnData = {
  key: string;
  file: Omit<TFileColumnsData, 'key' | 'updateAt' | 'createAt'>;
  family: string;
  variant: string;
  updatedAt: string;
  createdAt: string;
};

type GetFontsPayload = {
  deleteFont: (id: string) => void;
};

export const getFontsTableColumns: (
  props: GetFontsPayload
) => ColumnsType<TFontsColumnData> = ({ deleteFont }) => [
  {
    key: 'family',
    dataIndex: 'family',
    title: 'Семейство',
  },
  {
    key: 'variant',
    dataIndex: 'variant',
    title: 'Вариант',
  },
  {
    key: 'createdAt',
    dataIndex: 'createdAt',
    title: 'Дата создания',
    render: (value: string) => dayjs(value).format('DD.MM.YYYY HH:mm'),
  },
  {
    key: 'updatedAt',
    dataIndex: 'updatedAt',
    title: 'Дата обновления',
    render: (value: string) => dayjs(value).format('DD.MM.YYYY HH:mm'),
  },
  {
    key: 'key',
    dataIndex: 'key',
    title: '',
    render: (id: string) => (
      <Button
        shape="circle"
        onClick={() => deleteFont(id)}
        icon={<DeleteOutlined />}
        danger
      />
    ),
  },
];
