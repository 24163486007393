import { FC, useMemo } from 'react';

import { ButtonProps, Form, Modal } from 'antd';

import { GenerationSettingsForm } from '../../components/forms/generation-settings-form';
import { useStartProjectGenerationTask } from '../../hooks/projects/use-start-project-generation-task';
import { TGenerationSettings } from '../../types/task';
import { TBaseModalProps } from '../../types/ui';

type TProps = {
  projectId?: string;
} & TBaseModalProps;

export const GenerationSettingsModal: FC<TProps> = ({
  open,
  setOpen,
  projectId,
}) => {
  const [form] = Form.useForm<TGenerationSettings>();

  const { isLoading, handleStartGeneration } =
    useStartProjectGenerationTask(projectId);

  const handleCancel = () => {
    setOpen(false);
  };

  const okButtonProps: ButtonProps = useMemo(
    () => ({
      loading: isLoading,
    }),
    [isLoading]
  );

  return (
    <Modal
      title="Запустить генерацию с настройками"
      open={open}
      onCancel={handleCancel}
      onOk={form.submit}
      maskClosable={false}
      okButtonProps={okButtonProps}
      okText="Запустить"
    >
      <Form<TGenerationSettings>
        form={form}
        layout="vertical"
        onFinish={handleStartGeneration}
        initialValues={{
          checkWidthOverflow: true,
          skipUnavailable: false,
          transactional: true,
        }}
      >
        <GenerationSettingsForm />
      </Form>
    </Modal>
  );
};
