import { TGenerationLog } from '../../types/generation-logs';
import { TGenerationTask } from '../../types/task';
import { baseEmptyApi } from '../api';
import { TProjectTasksResponse } from '../projects/types';

export const generationTasksApi = baseEmptyApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getProjectGenerationTasks: builder.query<TProjectTasksResponse, string>({
      query: (projectId) => ({
        url: `/api/projects/${projectId}/generation-tasks`,
        method: 'GET',
      }),
    }),
    cancelGenerationTask: builder.mutation<TGenerationTask, string>({
      query: (taskId) => ({
        url: `api/generation-tasks/${taskId}/cancel`,
        method: 'POST',
      }),
    }),
    getTaskLogs: builder.query<TGenerationLog[], string>({
      query: (generationId) => ({
        url: `api/generation-tasks/${generationId}/logs`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useCancelGenerationTaskMutation,
  useGetProjectGenerationTasksQuery,
  useGetTaskLogsQuery,
} = generationTasksApi;
