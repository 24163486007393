import { FC, useCallback, useMemo, useState } from 'react';

import styled from '@emotion/styled';
import { Button, Space, Table, Typography } from 'antd';

import { useDeleteFontMutation, useGetFontsQuery } from '../../api/fonts';
import { useAppNotificationApi } from '../../context/providers/app-notifications-provider';
import {
  TFontsColumnData,
  getFontsTableColumns,
} from '../../tables/table-fonts';
import { CreateFontModal } from '../modals/create-font-modal';

const Title = styled(Typography.Title)`
  margin-top: 0;
  margin-bottom: 0 !important; // AntDesign не позволяет обойтись без !important
`;

const Header = styled(Space)`
  margin-bottom: 30px;
`;

export const FontPage: FC = () => {
  const [createFontModalOpen, setCreateFontModalOpen] = useState(false);

  const handleOpenFormModal = () => {
    setCreateFontModalOpen(true);
  };

  const { messageApi } = useAppNotificationApi();
  const { data, isLoading } = useGetFontsQuery();
  const [deleteFont] = useDeleteFontMutation();

  const columnData = useMemo<TFontsColumnData[]>(
    () =>
      data
        ? data.map((el) => ({
            key: el.id,
            variant: el.variant,
            family: el.family,
            updatedAt: el.updatedAt,
            createdAt: el.createdAt,
            file: {
              originalName: el.file.originalName,
              location: el.file.location,
              size: el.file.size,
            },
          }))
        : [],
    [data]
  );

  const handleDeleteFont = useCallback(async (id: string) => {
    const loading = messageApi.loading('Удаляем шрифт', 0);

    try {
      await deleteFont(id);
      loading();
      messageApi.success('Шрифт удален');
    } catch (e) {
      loading();
      messageApi.error('Не удалось удалить шрифт');
    }
  }, []);

  const columns = useMemo(
    () =>
      getFontsTableColumns({
        deleteFont: handleDeleteFont,
      }),
    []
  );

  return (
    <div>
      <Header size={12}>
        <Title level={2}>Шрифты</Title>
        <Button type="primary" onClick={handleOpenFormModal}>
          Добавить шрифт
        </Button>
      </Header>
      <Table
        dataSource={columnData}
        columns={columns}
        loading={isLoading}
        pagination={false}
      />
      <CreateFontModal
        open={createFontModalOpen}
        setOpen={setCreateFontModalOpen}
      />
    </div>
  );
};
