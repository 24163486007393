import React, { ReactNode } from 'react';

import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
  QuestionCircleOutlined,
  StopOutlined,
} from '@ant-design/icons';

export enum EGenerationTaskStatus {
  QUEUED = 'IN_PROGRESS',
  DONE = 'DONE',
  ERROR = 'ERROR',
  CREATED = 'CREATED',
  CANCELLED = 'CANCELLED',
}

export type EGenerationTaskStatusWithEmpty = EGenerationTaskStatus | 'empty';

export const ICON_BY_STATUS: Record<EGenerationTaskStatusWithEmpty, ReactNode> =
  {
    [EGenerationTaskStatus.DONE]: <CheckCircleOutlined />,
    [EGenerationTaskStatus.QUEUED]: <LoadingOutlined />,
    [EGenerationTaskStatus.ERROR]: <CloseCircleOutlined />,
    [EGenerationTaskStatus.CREATED]: <ExclamationCircleOutlined />,
    [EGenerationTaskStatus.CANCELLED]: <StopOutlined />,
    empty: <QuestionCircleOutlined />,
  };

export const TEXT_BY_GENERATION_STATUS: Record<
  EGenerationTaskStatusWithEmpty,
  {
    title: string;
    description: string;
  }
> = {
  [EGenerationTaskStatus.DONE]: {
    title: 'Генерация выполнена',
    description: 'Генерация успешно выполнена, данные обновлены',
  },
  [EGenerationTaskStatus.QUEUED]: {
    title: 'Генерация в очереди',
    description: 'Идет процесс генерации баннеров, пожалуйста подождите',
  },
  [EGenerationTaskStatus.ERROR]: {
    title: 'Произошла ошибка при генерации',
    description: 'Произошла непредвиденная ошибка при генерации баннера',
  },
  [EGenerationTaskStatus.CREATED]: {
    title: 'Добавлено в очередь',
    description: 'Запрос на генерацию добавлен в очередь',
  },
  [EGenerationTaskStatus.CANCELLED]: {
    title: 'Задача остановлена',
    description: 'Запрос на генерацию остановлена',
  },
  empty: {
    title: 'Нет информации',
    description: 'Информация о текущих задачах генерации не загружена',
  },
};

export type TGenerationSettings = {
  transactional: boolean;
  skipUnavailable: boolean;
  checkWidthOverflow: boolean;
  from?: number;
  to?: number;
};

export type TGenerationTask = {
  createdAt: string;
  id: string;
  projectId: string;
  status: EGenerationTaskStatus;
  settings: TGenerationSettings;
  createAt: string;
  updateAt: string;
};

export type TStartGenerationResponse = {
  createdAt: string;
  id: string;
  projectId: string;
  settings: TGenerationSettings;
  from: null | number;
  skipUnavailable: boolean;
  to: null | number;
  transactional: boolean;
  status: EGenerationTaskStatus;
  updatedAt: string;
};

export type TCurrentFullStatus = {
  lastStatus: EGenerationTaskStatusWithEmpty;
  lastTaskUpdateDate: string;
  isLoading: boolean;
  lastId?: string;
};
