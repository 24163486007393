import { FC, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import styled from '@emotion/styled';

import { APP_BASE_MENU } from '../../../constants/menus';
import { EPathKeyParams, EPaths } from '../../../routes/route-paths';
import { ECSSVars } from '../../../styles/global-styles';
import { BaseMenuItem, TMenuItem } from './base-menu-item';

const Wrapper = styled.aside`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  // Объявлена в ./index.tsx
  width: var(${ECSSVars.APP_MENU_WIDTH});
  background-color: ${({ theme }) => theme.MAIN.WHITE.C200};
`;

const Header = styled.header`
  padding: 20px 30px;
  height: 120px;
  display: flex;
  align-items: center;

  span {
    font-size: 60px;
    font-weight: bold;
    color: ${({ theme }) => theme.MAIN.ACCENT.C100};
  }
`;

const Menu = styled.ul`
  list-style: none;
  padding: 20px;
  margin: 0;
`;

export const AppBaseMenu: FC = () => {
  const location = useLocation();
  const params = useParams();

  const appMenuList: TMenuItem[] = useMemo(
    () =>
      APP_BASE_MENU.map((el) => {
        let isActive =
          el.to === EPaths.HOME
            ? el.to === location.pathname
            : location.pathname.startsWith(el.to);

        if (
          Object.prototype.hasOwnProperty.call(
            params,
            EPathKeyParams.PROJECT_ID
          ) &&
          el.to === EPaths.HOME
        ) {
          isActive = true;
        }

        return {
          ...el,
          active: isActive,
        };
      }),
    [location]
  );

  return (
    <Wrapper>
      <Header>
        <span>A</span>
      </Header>
      <Menu>
        {appMenuList.map((el) => (
          <BaseMenuItem key={el.key} menuItem={el} />
        ))}
      </Menu>
    </Wrapper>
  );
};
