import { FC, PropsWithChildren, createContext, useContext } from 'react';

import {
  TUsePagination,
  defaultPaginationState,
  usePagination,
} from '../../hooks/use-pagination';

type TContext = TUsePagination;
export const DEFAULT_BANNERS_PAGE_SIZE = 20;

const bannersPaginationContext = createContext<TContext>({
  ...defaultPaginationState,
  pagination: {
    ...defaultPaginationState.pagination,
    take: DEFAULT_BANNERS_PAGE_SIZE,
  },
});

export const useBannersPaginationContext = () =>
  useContext<TContext>(bannersPaginationContext);

export const BannersPaginationProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const pagination = usePagination(DEFAULT_BANNERS_PAGE_SIZE);
  return (
    <bannersPaginationContext.Provider value={pagination}>
      {children}
    </bannersPaginationContext.Provider>
  );
};
