import { FC, useCallback, useMemo } from 'react';

import { ButtonProps, Form, Modal } from 'antd';

import { useCreateFontMutation } from '../../api/fonts';
import { TFontForm } from '../../api/generation-tasks/types';
import { FontBaseForm } from '../../components/forms/font-base-form';
import { FontVariantForm } from '../../components/forms/font-variant-form';
import { FormTitle } from '../../components/forms/form-components';
import { useAppNotificationApi } from '../../context/providers/app-notifications-provider';
import { TBaseModalProps } from '../../types/ui';

type TProps = TBaseModalProps;

export const CreateFontModal: FC<TProps> = ({ open, setOpen }) => {
  const [form] = Form.useForm<TFontForm>();
  const { messageApi } = useAppNotificationApi();

  const [createFont, { isLoading }] = useCreateFontMutation();

  const handleCancel = () => {
    setOpen(false);
  };

  const handleFormFinish = useCallback(async (values: TFontForm) => {
    try {
      await createFont({
        family: values.family,
        variants: values.variants.map((el) => ({
          fileId: el.file.response?.id as string,
          variant: el.variant,
        })),
      }).unwrap();

      messageApi.success('Шрифт успешно добавлен');
      setOpen(false);
      form.resetFields();
    } catch (e) {
      // eslint-disable-next-line
      console.error('Error on feed creation', e);
      messageApi.error('Произошла ошибка при создании шрифта');
    }
  }, []);

  const okButtonProps: ButtonProps = useMemo(
    () => ({
      loading: isLoading,
    }),
    [isLoading]
  );

  return (
    <Modal
      title="Добавить шрифт"
      open={open}
      onCancel={handleCancel}
      onOk={form.submit}
      maskClosable={false}
      okButtonProps={okButtonProps}
      okText="Сохранить"
    >
      <Form form={form} layout="vertical" onFinish={handleFormFinish}>
        <FormTitle>Основная информация</FormTitle>
        <FontBaseForm />
        <FormTitle>Варианты шрифта</FormTitle>
        <FontVariantForm />
      </Form>
    </Modal>
  );
};
