import { useCallback, useMemo } from 'react';

import { useStartProjectBannersGenerationMutation } from '../../api/projects';
import { useAppNotificationApi } from '../../context/providers/app-notifications-provider';
import { TGenerationSettings } from '../../types/task';

type TUseStartGeneration = {
  isLoading: boolean;
  handleStartGeneration: (settings?: TGenerationSettings) => Promise<void>;
};

export const useStartProjectGenerationTask = (projectId?: string) => {
  const { messageApi } = useAppNotificationApi();
  const [startGeneration, { isLoading }] =
    useStartProjectBannersGenerationMutation();

  const handleStartGeneration = useCallback(
    async (settings?: TGenerationSettings) => {
      if (projectId) {
        try {
          await startGeneration({ projectId, settings }).unwrap();
          void messageApi.success('Генерация успешно запущена');
        } catch (e) {
          void messageApi.error(
            'Ошибка при запуске генерации. Попробуйте позже'
          );
        }
      } else {
        void messageApi.error(
          'Отсутствует идентификатор проекта, пожалуйста проверьте URL адрес страницы'
        );
      }
    },
    [projectId]
  );

  return useMemo<TUseStartGeneration>(
    () => ({
      handleStartGeneration,
      isLoading,
    }),
    [handleStartGeneration]
  );
};
