import { useCallback } from 'react';

import { useCancelGenerationTaskMutation } from '../../api/generation-tasks';
import { useAppNotificationApi } from '../../context/providers/app-notifications-provider';

type TUseCancelGenerationTask = [
  (id: string) => Promise<void>,
  { isLoading: boolean },
];

export const useCancelGenerationTask: () => TUseCancelGenerationTask = () => {
  const [cancelGenerationTask, { isLoading }] =
    useCancelGenerationTaskMutation();

  const { messageApi } = useAppNotificationApi();

  const handleCancelGenerationTask = useCallback(async (id: string) => {
    try {
      if (!id) {
        return;
      }

      await cancelGenerationTask(id);
      messageApi.success('Задача на генерацию остановлена');
    } catch (e) {
      messageApi.error('Не удалось остановить задачу на генерацию');
    }
  }, []);

  return [handleCancelGenerationTask, { isLoading }];
};
