import { FC } from 'react';

import styled from '@emotion/styled';
import { Button, Typography } from 'antd';

import { TFinanceFeatures } from '../../../types/finance';
import { LAQUO, RAQUO } from '../../../utils/typography';

export enum EFinanceHeadColor {
  BLACK = 'BLACK',
  RED = 'RED',
  CYAN = 'CYAN',
}

const Wrapper = styled.div`
  min-width: 320px;
  overflow: hidden;
  border-radius: 30px;
`;

const Header = styled.div<{ color: EFinanceHeadColor }>`
  display: flex;
  flex-direction: column;
  padding: 12px 18px;
  background-color: ${({ theme, color }) => theme.MAIN.CORP[color]};

  .bold {
    font-weight: bold;
  }

  span {
    color: ${({ theme }) => theme.MAIN.WHITE.C100} !important;
    font-size: 18px;
  }
`;

const Inner = styled.div`
  background-color: ${({ theme }) => theme.MAIN.CORP.WHITE};
  padding: 18px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  min-height: 310px;

  button {
    margin-top: auto;
  }
`;

const Label = styled(Typography.Text)`
  font-size: 16px;
`;

const Value = styled(Typography.Text)`
  font-size: 18px;
  font-weight: bold;
`;

const FeatureWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

type TProps = {
  color?: EFinanceHeadColor;
  isCurrent?: boolean;
  planName: string;
  features: TFinanceFeatures;
};

export const FinancePlan: FC<TProps> = ({
  color,
  planName,
  isCurrent,
  features,
}) => {
  return (
    <Wrapper>
      <Header color={color ?? EFinanceHeadColor.RED}>
        <Typography.Text>Тариф</Typography.Text>
        <Typography.Text className="bold">
          {LAQUO + planName + RAQUO}
        </Typography.Text>
      </Header>
      <Inner>
        <FeatureWrapper>
          <Label>Количество SKU</Label>
          <Value>до {features.skuLimit}</Value>
        </FeatureWrapper>
        <FeatureWrapper>
          <Label>Обновление креативов</Label>
          <Value>{features.updatesPerDay} раз в сутки</Value>
        </FeatureWrapper>
        <FeatureWrapper>
          <Label>Стоимость</Label>
          <Value>
            {features.price ? (
              <>
                {features.connectionPrice && (
                  <>
                    {features.connectionPrice.toLocaleString()} руб. -
                    подключение
                    <br />
                  </>
                )}
                {features.price.toLocaleString()} руб. - ежемесячно
              </>
            ) : (
              'по запросу'
            )}
          </Value>
        </FeatureWrapper>
        <Button type="primary" disabled={isCurrent}>
          {isCurrent ? 'Текущий тариф' : 'Оплатить'}
        </Button>
      </Inner>
    </Wrapper>
  );
};
