import { FC, ReactNode } from 'react';

import styled from '@emotion/styled';
import { Space, Typography } from 'antd';

type TProps = {
  title?: ReactNode;
  value?: ReactNode;
};

const Cell = styled(Space)`
  align-items: center;
  gap: 4px;
  padding-block: 2px;
`;

const Icon = styled.div`
  font-size: 20px;
`;

export const IconTableCell: FC<TProps> = ({ title, value }) => (
  <Cell>
    {value && <Icon>{value}</Icon>}
    {title && <Typography.Text>{title}</Typography.Text>}
  </Cell>
);
