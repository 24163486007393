import { FC, useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { DownloadOutlined, FileZipOutlined } from '@ant-design/icons';
import { Button, Dropdown, Space } from 'antd';

import { useDownloadFilesZipMutation } from '../../api/common';
import { useGetProjectBannersQuery } from '../../api/projects';
import { useAppNotificationApi } from '../../context/providers/app-notifications-provider';
import { useBannersPaginationContext } from '../../context/providers/banners-pagination-provider';
import { useStartProjectGenerationTask } from '../../hooks/projects/use-start-project-generation-task';
import { EGenerationTaskStatus, TCurrentFullStatus } from '../../types/task';
import { GenerationSettingsModal } from '../modals/generation-settings-modal';

import { MenuProps } from 'antd/es/menu/menu';

type TProps = {
  projectId?: string;
  scheduledFeedURL?: string | null;
  projectTaskFullStatus: TCurrentFullStatus;
};

export const ProjectBannersButtons: FC<TProps> = ({
  projectId,
  scheduledFeedURL,
  projectTaskFullStatus,
}) => {
  const [generationSettingsModalOpen, setGenerationSettingsModalOpen] =
    useState(false);
  const [downloadBannersPending, setDownloadBannersPending] = useState(false);

  const { pagination } = useBannersPaginationContext();

  const { messageApi } = useAppNotificationApi();

  const [downloadFiles] = useDownloadFilesZipMutation();

  const { data: bannersData } = useGetProjectBannersQuery(
    { projectId, pagination },
    {
      skip: !projectId,
    }
  );

  const { isLoading: isStartGenerationLoading, handleStartGeneration } =
    useStartProjectGenerationTask(projectId);

  const handleDownloadBanners = useCallback(async () => {
    if (bannersData && bannersData.data.length > 0) {
      setDownloadBannersPending(true);
      try {
        await downloadFiles(bannersData.data.map((el) => el.imageURL)).unwrap();
      } catch (e) {
        void messageApi.error('Произошла ошибка при скачивании');
      } finally {
        setDownloadBannersPending(false);
      }
    }
  }, [bannersData]);

  const simpleStartGeneration = () => {
    void handleStartGeneration();
  };

  const startButtonItems: MenuProps['items'] = useMemo(
    () => [
      {
        key: 'start-with-settings',
        label: 'Запустить с настройками',
      },
    ],
    []
  );

  return (
    <Space>
      <Dropdown.Button
        menu={{
          items: startButtonItems,
          onClick: (e: { key: string }) => {
            if (e.key === 'start-with-settings') {
              setGenerationSettingsModalOpen(true);
            }
          },
        }}
        type="primary"
        loading={
          isStartGenerationLoading ||
          projectTaskFullStatus.lastStatus === EGenerationTaskStatus.QUEUED
        }
        onClick={simpleStartGeneration}
      >
        Запустить генерацию
      </Dropdown.Button>
      {bannersData && bannersData.data.length > 0 && (
        <Button
          type="primary"
          ghost
          loading={downloadBannersPending}
          icon={<FileZipOutlined />}
          onClick={handleDownloadBanners}
        >
          Скачать баннеры .zip
        </Button>
      )}
      {scheduledFeedURL && (
        <Link to={scheduledFeedURL} download target="_blank">
          <Button type="primary" ghost icon={<DownloadOutlined />}>
            Скачать фид .xml
          </Button>
        </Link>
      )}
      <GenerationSettingsModal
        projectId={projectId}
        open={generationSettingsModalOpen}
        setOpen={setGenerationSettingsModalOpen}
      />
    </Space>
  );
};
