import { FC } from 'react';

import { Form, Input } from 'antd';

export const FontBaseForm: FC = () => (
  <Form.Item
    label="Семейство шрифта"
    name="family"
    rules={[
      {
        required: true,
        message: 'Поле обязательно для заполнения',
      },
    ]}
  >
    <Input placeholder="Семейство" />
  </Form.Item>
);
