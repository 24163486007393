import { FC, memo } from 'react';

import styled from '@emotion/styled';
import { Typography } from 'antd';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type TProps = {
  projectName?: string;
};

const ProjectPageHeaderSection: FC<TProps> = ({ projectName }) => (
  <Wrapper>
    <Typography.Title>{projectName}</Typography.Title>
  </Wrapper>
);

export default memo(ProjectPageHeaderSection);
