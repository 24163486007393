import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';

import { useDeleteFileMutation } from '../api/common';
import { TFileUploadResponse } from '../types/api';

import { UploadChangeParam, UploadFile } from 'antd/es/upload/interface';

type FileItem = UploadFile<TFileUploadResponse>;

type TFileUploadHelpers = {
  fileList: FileItem[] | undefined;
  setFileList: Dispatch<SetStateAction<FileItem[] | undefined>>;
  handleFileChange: (e: UploadChangeParam<FileItem>) => Promise<void>;
  deleteFile: ReturnType<typeof useDeleteFileMutation>[0];
};

export const useFileUploadHelpers = () => {
  const [fileList, setFileList] = useState<FileItem[]>();

  const [deleteFile] = useDeleteFileMutation();

  const handleFileChange = useCallback(
    async (e: UploadChangeParam<UploadFile<TFileUploadResponse>>) => {
      setFileList(e.fileList);
      if (e?.file?.status === 'removed' && e.file?.response?.id) {
        await deleteFile(e.file.response.id);
      }
    },
    []
  );

  return useMemo<TFileUploadHelpers>(
    () => ({
      fileList,
      setFileList,
      deleteFile,
      handleFileChange,
    }),
    [fileList]
  );
};
