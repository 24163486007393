import { FC } from 'react';

import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Form, FormListFieldData, Input, Space, Upload } from 'antd';

import { FILE_UPLOAD_API } from '../../api';
import { TFontVariant } from '../../api/generation-tasks/types';
import { useFileUploadHelpers } from '../../hooks/use-file-upload-helpers';
import { normSingleFile } from '../../utils/files';

type FontVariantItemProps = {
  field: FormListFieldData;
  remove: (index: number | number[]) => void;
};

const VariantItemForm: FC<FontVariantItemProps> = ({ field, remove }) => {
  const { handleFileChange, fileList, deleteFile } = useFileUploadHelpers();

  const onDelete = async () => {
    if (!fileList) {
      remove(field.name);
      return;
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const file of fileList) {
      if (file.response?.id) {
        // eslint-disable-next-line no-await-in-loop
        await deleteFile(file.response?.id);
      }
    }

    remove(field.name);
  };

  return (
    <Space align="start">
      <Form.Item
        name={[field.name, 'file']}
        valuePropName="file"
        getValueFromEvent={normSingleFile}
        rules={[
          {
            required: true,
            message: 'Файл обязателен для загрузки',
          },
        ]}
      >
        <Upload
          name="file"
          action={FILE_UPLOAD_API}
          onChange={handleFileChange}
          fileList={fileList}
          listType="text"
        >
          <Button icon={<UploadOutlined />}>Загрузить шрифт</Button>
        </Upload>
      </Form.Item>
      <Form.Item
        name={[field.name, 'variant']}
        rules={[
          {
            required: true,
            message: 'Поле обязательно для заполнения',
          },
        ]}
      >
        <Input placeholder="Навание начертания" />
      </Form.Item>
      <Button shape="circle" icon={<DeleteOutlined />} onClick={onDelete} />
    </Space>
  );
};

export const FontVariantForm: FC = () => (
  <Form.List
    name="variants"
    rules={[
      {
        validator: async (_, value: Partial<TFontVariant>[]) => {
          if (!value || value.length < 1) {
            return Promise.reject(new Error('Добавьте минимум один вариант'));
          }
          return Promise.resolve();
        },
      },
    ]}
  >
    {(fields, { add, remove }, { errors }) => (
      <div style={{ display: 'flex', rowGap: 16, flexDirection: 'column' }}>
        {fields.map((data) => (
          <VariantItemForm key={data.key} field={data} remove={remove} />
        ))}
        <Form.Item>
          <Button onClick={() => add()}>Добавить вариант</Button>
          <Form.ErrorList errors={errors} />
        </Form.Item>
      </div>
    )}
  </Form.List>
);
