export enum EPaths {
  HOME = '/',
  LOGIN = '/login',
  REGISTER = '/register',
  PLUGIN_AUTH = '/plugin-auth',
  PROFILE = '/profile',
  FINANCE = '/finance',
  FEEDS = '/feeds',
  FONTS = '/fonts',
}

export enum EPathKeyParams {
  PROJECT_ID = 'projectId',
  GENERATION_ID = 'generationId',
}

export enum ESubPaths {
  BANNERS = 'banners',
  GENERATION_TASKS = 'generation-tasks',
  TEMPLATE = 'template',
  LOGS = 'logs',
}

export type TSubPathsPathParams<T extends ESubPaths> = {
  [ESubPaths.BANNERS]: {
    [EPathKeyParams.GENERATION_ID]?: string;
  };
  [ESubPaths.GENERATION_TASKS]: never;
  [ESubPaths.TEMPLATE]: never;
  [ESubPaths.LOGS]: {
    [EPathKeyParams.GENERATION_ID]?: string;
  };
}[T];

export const UNSECURE_PATHS: EPaths[] = [EPaths.LOGIN, EPaths.REGISTER];
